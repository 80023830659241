import type { WidgetConfig, WidgetType } from './types/config';

(() => {
  const reloadVenueData = () => {
    // @ts-expect-error
    if (window.performance.getEntriesByType('navigation')[0].type === 'back_forward') {
      window.location.reload();
    }
  };

  const umaiWidget: WidgetConfig = {};
  if (window.umaiWidget) {
    return;
  }

  function loadScript() {
    const widgetScript = document.createElement('script');
    widgetScript.async = true;
    widgetScript.type = 'module';
    widgetScript.src = `${import.meta.env.VITE_WIDGET_URL}?date=${encodeURIComponent(
      new Date().toString()
    )}`;

    const entry = document.getElementsByTagName('script')[0];
    if (entry.parentNode) {
      entry.parentNode.insertBefore(widgetScript, entry);
    }
  }

  function loadStripeScript() {
    const stripeScript = document.createElement('script');
    stripeScript.async = true;
    stripeScript.id = 'stripe-js';
    stripeScript.src = 'https://js.stripe.com/v3/';

    const entry = document.getElementsByTagName('script')[0];
    if (entry.parentNode) {
      entry.parentNode.insertBefore(stripeScript, entry);
    }
  }

  function loadStyle() {
    const head = document.getElementsByTagName('head')[0];
    const widgetLink = document.createElement('link');
    widgetLink.rel = 'stylesheet';
    widgetLink.type = 'text/css';
    widgetLink.href = `${import.meta.env.VITE_WIDGETCSS_URL}?date=${encodeURIComponent(
      new Date().toString()
    )}`;
    widgetLink.media = 'all';
    head.appendChild(widgetLink);
  }

  function onModalReady() {
    if (typeof umaiWidget.openModal === 'function') {
      umaiWidget.openModal();
    } else {
      setTimeout(() => {
        onModalReady();
      }, 50);
    }
  }

  // TODO: This should be loaded for venues that don't use payments
  loadStripeScript();

  // Only add these when bundling
  // Vite mode is one if these: dev, staging, prod
  if (['dev', 'staging', 'prod'].includes(import.meta.env.MODE)) {
    loadStyle();
    loadScript();
  }

  umaiWidget.openWidget = () => {
    // this re-load widget with correct venue Data when a user navigate using browser back forward button
    reloadVenueData();
    if (umaiWidget.validConfig) {
      onModalReady();
    }
  };

  function reOpenWidget() {
    // This is to reopen the widget after payment redirect
    const url = new URL(window.location.href);
    const paymentIntent = url.searchParams.get('payment_intent');
    const apiKey = url.searchParams.get('api_key');
    const widgetType = url.searchParams.get('widget_type');

    if (paymentIntent) {
      umaiWidget.config!({
        apiKey,
        widgetType,
      } as WidgetConfig);
      umaiWidget.openWidget!();
    }
  }

  const checkAnchor = (anchor?: string) =>
    anchor && window.location?.hash?.toLowerCase()?.includes(anchor.toLowerCase());

  function getWidgetType(config: WidgetConfig = {}): WidgetType {
    if (config.parseAnchors) {
      if (checkAnchor(config.reservationAnchor)) {
        return 'reservation';
      }
      if (checkAnchor(config.deliveryAnchor)) {
        return 'delivery';
      }
      if (checkAnchor(config.takeawayAnchor)) {
        return 'takeaway';
      }
      if (checkAnchor(config.giftcardAnchor) || checkAnchor('giftcard')) {
        return 'giftcard';
      }
    }
    return (config.widgetType?.toLowerCase() as WidgetType) || 'reservation';
  }

  umaiWidget.config = (venue) => {
    umaiWidget.validConfig = false;
    umaiWidget.apiKey = null;
    umaiWidget.SourceIsUmaiWebsite = false;
    umaiWidget.gaWidgetRestaurantId = '';
    umaiWidget.gaWidgetType = 'venue';
    umaiWidget.platformSignup = '';
    const hostname = window.location.hostname.replace('www.', '');
    // Quick fix because of FB issue and new umai.tech
    if (hostname === import.meta.env.VITE_UMAI_DOMAIN || hostname === 'umai.tech') {
      umaiWidget.SourceIsUmaiWebsite = true;
    }
    if (venue !== null && typeof venue === 'object') {
      if ('apiKey' in venue) {
        umaiWidget.validConfig = true;
        umaiWidget.apiKey = venue.apiKey;
        umaiWidget.parseAnchors = venue.parseAnchors;
        umaiWidget.reservationAnchor = venue.reservationAnchor;
        umaiWidget.giftcardAnchor = venue.giftcardAnchor;
        umaiWidget.deliveryAnchor = venue.deliveryAnchor;
        umaiWidget.takeawayAnchor = venue.takeawayAnchor;
        umaiWidget.widgetType = getWidgetType(venue);
        umaiWidget.platformSignup = venue.platformSignup;
      } else {
        console.error('umaiWidget: apiKey not present!');
      }
    }

    const { pathname } = window.location;
    // y - confirmation
    // c - cancel
    // e - edit or cancel
    // f - confirm or edit or cancel
    // g - confirm or late or edit or cancel
    // p - manual payment
    umaiWidget.isEditingInB2B =
      umaiWidget.SourceIsUmaiWebsite &&
      (pathname.startsWith('/y/') ||
        pathname.startsWith('/c/') ||
        pathname.startsWith('/e/') ||
        pathname.startsWith('/f/') ||
        pathname.startsWith('/g/') ||
        pathname.startsWith('/p/'));
  };

  window.umaiWidget = umaiWidget;

  reOpenWidget();
})();
